import React, { useState, useEffect } from "react";
import Tabs from "../tab";
import Layout from "../../containers/Layout";
import axios from "axios";
import config from "../../config";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { notification, Switch, Spin, Modal } from "antd";
import {
  checkPasswordStrength,
  strongPasswordText,
} from "../../helperFunctions/functions";
import { ThreeDots } from "react-loader-spinner";
export default function Security() {
  const [strongPasswordError, setStrongPasswordError] = useState(false);

  //pin
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [cPin, setCPin] = useState("");
  const [loadReset, setLoadReset] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [resetLoad, onResetLoad] = useState(false);

  const [oldPinEye, setOldPinEyeConfirm] = useState(false);
  const [newPinEye, setNewPinEyeConfirm] = useState(false);
  const [cPinEye, setCPinEyeConfirm] = useState(false);

  const [oldPassEye, setOldPassEyeConfirm] = useState(false);
  const [newPassEye, setNewPassEyeConfirm] = useState(false);
  const [cPassEye, setCPassEyeConfirm] = useState(false);

  //password
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cPass, setCPass] = useState("");

  const [resetPinVal, setresetPinVal] = useState("");
  const [resetPinValConfirm, setresetPinValConfirm] = useState("");
  const [resetToken, setResetToken] = useState("");

  //2Fa
  const [toggle, setToggle] = useState(false);
  const [serverError, setServerError] = useState(false);

  //loaders
  const [loadPin, setLoadPin] = useState(false);
  const [loadPass, setLoadPass] = useState(false);

  const switchChange = (checked) => {
    TwoFA(checked);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    axios
      .get(`${config.baseUrl}/v1/users`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setToggle(res.data.result.use2FA);
        } else {
        }
      })
      .catch((err) => {
        setServerError(true);
        setTimeout(() => {
          setServerError(false);
        }, 2500);
      });
  };

  const openNotificationWithIcon = (leadText, type, msg) => {
    notification[type]({
      message: leadText,
      description: msg,
    });
  };

  const passwordStrength = checkPasswordStrength(newPass);

  const changePass = (e) => {
    e.preventDefault();
    if (passwordStrength === "Weak") {
      setStrongPasswordError(true);

      setTimeout(() => {
        setStrongPasswordError(false);
      }, 10000);
      return;
    }

    const data = {
      confirmPassword: cPass,
      newPassword: newPass,
      oldPassword: oldPass,
    };
    if (data.confirmPassword && data.newPassword && data.oldPassword) {
      setLoadPass(true);
      axios
        .post(`${config.baseUrl}/v1/users/change-password`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadPass(false);
          if (res.data.status) {
            setOldPass("");
            setNewPass("");
            setCPass("");
            openNotificationWithIcon("Logout", "success", res.data.message);
          } else {
            openNotificationWithIcon("Logout", "error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadPass(false);
        });
    } else {
      Notification(
        "error",
        "Password Error!",
        "Old Password, New Password, and Confirm-Pasword are required to change your PASSWORD"
      );
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const changePin = (e) => {
    e.preventDefault();
    const data = {
      confirmPin: cPin,
      newPin: newPin,
      oldPin: oldPin,
    };
    if (data.confirmPin && data.newPin && data.oldPin) {
      setLoadPin(true);
      axios
        .post(`${config.baseUrl}/v1/users/change-pin`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadPin(false);
          if (res.data.status) {
            setResetToken("");
            setresetPinVal("");
            setresetPinValConfirm("");
            openNotificationWithIcon("Reset Pin", "success", res.data.message);
          } else {
            openNotificationWithIcon("Reset Pin", "error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadPin(false);
        });
    } else {
      Notification(
        "error",
        "Pin Error",
        "Old Pin, New Pin, and Confirm-Piin are required to change your PIN"
      );
    }
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "oldpin":
          setOldPin(truncatedValue);
          break;
        case "newpin":
          setNewPin(truncatedValue);
          break;
        case "cpin":
          setCPin(truncatedValue);
          break;
        case "rpin":
          setresetPinVal(truncatedValue);

          break;
        case "cRpin":
          setresetPinValConfirm(truncatedValue);
          break;
        case "token":
          setResetToken(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const resetPIN = (e) => {
    e.preventDefault();
    const data = {
      confirmNewPin: resetPinValConfirm,
      newPin: resetPinVal,
      token: resetToken,
    };
    if (data.confirmNewPin && data.newPin && data.token) {
      if (data?.confirmNewPin === data.newPin) {
        onResetLoad(true);
        axios
          .post(`${config.baseUrl}/v1/users/authorize-pin-reset`, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            onResetLoad(false);
            if (res.data.status) {
              setResetModal(false);
              setresetPinVal("");
              setresetPinValConfirm("");
              setResetToken("");
              openNotificationWithIcon(
                "Change Pin",
                "success",
                res.data.message
              );
            } else {
              openNotificationWithIcon("Change Pin", "error", res.data.message);
            }
          })
          .catch((err) => {
            onResetLoad(false);
          });
      } else {
        Notification(
          "error",
          "Pin Error",
          "New Pin and Confirm new pin do not match"
        );
      }
    } else {
      Notification("error", "Reset Pin Error", "All fields are required");
    }
  };

  const TwoFA = (toggle) => {
    axios
      .put(`${config.baseUrl}/v1/users/2fa`, toggle, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {});
  };

  const onResetPin = (e) => {
    e.preventDefault();
    setLoadReset(true);
    axios
      .post(
        `${config.baseUrl}/v1/users/initiate-pin-reset`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadReset(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setResetModal(true);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadReset(false);
      });
  };

  return (
    <Layout>
      <Tabs />
      <form>
        <div className="row">
          <div class="col-sm-12 col-md-6">
            <div class="card">
              <div class="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <b>Change Pin</b>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={onResetPin}
                  >
                    Reset Pin
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div className="text-center">{loadReset && <Spin />}</div>
                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!oldPinEye ? "password" : "text"}
                          class="form-control"
                          maxLength="4"
                          value={oldPin}
                          style={{ border: 0 }}
                          onChange={handleChangeNumber.bind(this, "oldpin", 4)}
                        />
                        <label for="floatingPassword">Old Pin</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setOldPinEyeConfirm(!oldPinEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!oldPinEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!newPinEye ? "password" : "text"}
                          class="form-control"
                          maxLength="4"
                          value={newPin}
                          style={{ border: 0 }}
                          onChange={handleChangeNumber.bind(this, "newpin", 4)}
                        />
                        <label for="floatingPassword">New Pin</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setNewPinEyeConfirm(!newPinEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!newPinEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!cPinEye ? "password" : "text"}
                          class="form-control"
                          maxLength="4"
                          value={cPin}
                          style={{ border: 0 }}
                          onChange={handleChangeNumber.bind(this, "cpin", 4)}
                        />
                        <label for="floatingPassword">Confirm Pin</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setCPinEyeConfirm(!cPinEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!cPinEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={changePin}
                  >
                    {loadPin ? (
                      <ThreeDots
                        visible={loadPin}
                        height="20"
                        width="50"
                        color="#ffffff"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="card">
              <div class="card-header py-4">
                <b>Change Password</b>
              </div>
              <div class="card-body">
                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!oldPassEye ? "password" : "text"}
                          class="form-control"
                          value={oldPass}
                          style={{ border: 0 }}
                          onChange={(e) => setOldPass(e.target.value)}
                        />
                        <label for="floatingPassword">Old Password</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setOldPassEyeConfirm(!oldPassEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!oldPassEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!newPassEye ? "password" : "text"}
                          class="form-control"
                          value={newPass}
                          style={{ border: 0 }}
                          onChange={(e) => setNewPass(e.target.value)}
                        />
                        <label for="floatingPassword">New Password</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setNewPassEyeConfirm(!newPassEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!newPassEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                  {strongPasswordError &&
                    strongPasswordText.map((item) => (
                      <li className="text-danger font-weight-bold">
                        {item.text}
                      </li>
                    ))}
                </div>

                <div className="mb-3">
                  <div
                    className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                    style={{ borderRadius: "10px" }}
                  >
                    <div
                      className=""
                      style={{
                        width: "90%",
                      }}
                    >
                      <div class="form-floating">
                        <input
                          type={!cPassEye ? "password" : "text"}
                          class="form-control"
                          value={cPass}
                          style={{ border: 0 }}
                          onChange={(e) => setCPass(e.target.value)}
                        />
                        <label for="floatingPassword">Confirm Password</label>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center h-100 pointer"
                      onClick={() => setCPassEyeConfirm(!cPassEye)}
                      style={{
                        width: "10%",
                      }}
                    >
                      {!cPassEye ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={changePass}
                >
                  {loadPass ? (
                    <ThreeDots
                      visible={loadPass}
                      height="20"
                      width="50"
                      color="#ffffff"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <br />

      <div className="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header font-weight-bold">
            <b>Two Factor Authentication (2FA) {toggle}</b>
          </div>
          <div class="card-body">
            {toggle && (
              <form>
                <Switch defaultChecked={true} onChange={switchChange} />
              </form>
            )}
            {!toggle && (
              <form>
                <Switch defaultChecked={false} onChange={switchChange} />
              </form>
            )}
          </div>
        </div>
      </div>
      <Modal
        title="Reset Pin"
        visible={resetModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setResetModal(false);
          setresetPinVal("");
          setresetPinValConfirm("");
          setResetToken("");
        }}
      >
        <div className="container ">
          {resetLoad && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              New Pin
            </label>
            <input
              type="password"
              class="form-control"
              value={resetPinVal}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={handleChangeNumber.bind(this, "rpin", 4)}
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Confirm New Pin
            </label>
            <input
              type="password"
              class="form-control"
              value={resetPinValConfirm}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={handleChangeNumber.bind(this, "cRpin", 4)}
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Reset Token (Token sent to email)
            </label>
            <input
              type="text"
              class="form-control"
              value={resetToken}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={handleChangeNumber.bind(this, "token", 5)}
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary mt-2"
            style={{ background: "#1FC157", color: "white" }}
            onClick={resetPIN}
          >
            Submit
          </button>
        </div>
      </Modal>
    </Layout>
  );
}

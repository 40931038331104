import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import Location from "../../../containers/Location/Location1";
import { ThreeDots } from "react-loader-spinner";
import Location2 from "../../../containers/Location/Location2";
import moment from "moment";
import { notification, Spin, DatePicker } from "antd";
import dayjs from "dayjs";
const dateFormat = "YYYY/MM/DD";
export default function EmployeeInfo({
  setTabs,
  setTaxData,
  setBankLists,
  setPfaData,
  setFullData,
  setCountryDatar,
  preloadData,
  info,
}) {
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [personalEmail, setPersonalEmail] = useState("")
  const [countryData, setCountryData] = useState([]);
  const [mmanualAddress, setMnaualAddress] = useState(false);
  const [mmanualAddressNok, setMnaualAddressNok] = useState(false);
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [nokA, setNokA] = useState("");
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [pfa, setPfa] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");

  const [employeeType, setEmployeeType] = useState("");
  const [position, setPosition] = useState("");
  const [pfaRsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [nextOfKin, setNextOfkin] = useState("");
  const [nextOfKinPhone, setNextOfkinPhone] = useState("");
  const [level, setLevel] = useState("");
  const [nextOfKinAddress, setNextOfkinAddress] = useState("");

  const countryChange = (e) => {
    if (e.target.value) {
      setValue("country", e.target?.value);
      setCountry(e.target.value);
      getPfa(e.target.value);
      getTaxStates(e.target.value);
      getBanksByCountry(e.target.value);
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`)
      .then((res) => {
        if (res.data.status) {
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/PFA/${country}`)
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;
         
          setPfaData(
            arr?.map((d) => ({
              value: d?.code,
              label: d?.value,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (info?.id) {
      setValue("firstName", info?.firstName);
      setValue("lastName", info?.lastName);
      setValue("otherName", info?.otherName);
      setValue("dob",info?.dateOfBirth);
      setValue("gender", info?.gender);
      setValue("country", info?.country);
      setValue("maritalStatus", info?.maritalStatus);
      setValue("personalEmail", info?.personalEmail);
      setValue("address", info?.contactAddress);
      setValue("addressKin", info?.nextOfKinAddress);
      setValue("nok", info?.nok ? info?.nok : info?.nextOfKin);
      setValue('nokA',info?.nextOfKinAddress)
      setValue(
        "nokPhone",
        info?.nokPhone ? info?.nokPhone : info?.nextOfKinPhone
      );
      setValue("mobile", info?.mobile);

      setFirstName(info?.firstName);
      setLastName(info?.lastname);
      setNokA(info?.nextOfKinAddress)
      setOthername(info?.otherName);
      setCountry(info?.country);
      setDob(info?.dateOfBirth);
      setGender(info?.gender);
      setPersonalEmail(info?.personalEmail)
      setMaritalStatus(info?.maritalStatus);
      setMobile(info?.mobile);
      setNextOfkin(info?.nok);
      setNextOfkinPhone(info?.nokPhone);
      

      getPfa(info?.country);
      getTaxStates(info?.country);
      getBanksByCountry(info?.country);
    }
  }, [info]);

  useEffect(() => {
    if (preloadData?.firstName) {
      setValue("firstName", preloadData?.firstName);
      setValue("lastName", preloadData?.lastName);
      setValue("otherName", preloadData?.otherName);
      setValue("dob", preloadData?.dob);
      setValue("country", preloadData?.country);
      setValue("gender", preloadData?.gender);
      setValue("maritalStatus", preloadData?.maritalStatus);
      setValue("nok", preloadData?.nok);
      setValue("personalEmail", preloadData?.personalEmail)
      setValue("nokPhone", preloadData?.nokPhone);
      setValue("mobile", preloadData?.mobile);

      setFirstName(preloadData?.firstName);
      setLastName(preloadData?.lastname);
      setPersonalEmail(preloadData?.personalEmail)
      setOthername(preloadData?.otherName);
      setCountry(preloadData?.country);
      setDob(preloadData?.dob);
      setGender(preloadData?.gender);
      setMaritalStatus(preloadData?.maritalStatus);
      setMobile(preloadData?.mobile);
      setNextOfkin(preloadData?.nok);
      setNextOfkinPhone(preloadData?.nokPhone);
    }
  }, [preloadData]);

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`)
      .then((res) => {
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
          setCountryDatar(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getCounties();
  }, []);

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNextOfkinPhone(truncatedValue);
          break;
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;

        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const onChange = (date, dateString) => {
    setDob(date);
    setValue("dob", date);
    console.log(date)
  };

  const handleInputChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const lettersOnly = inputValue.replace(/[^A-Za-z]/g, "");
    const lettersOnlyandSpace = inputValue.replace(/^(?! )[A-Za-z\s]*$/g, "");
    const numbersOnly = inputValue.match(/\d+/g);
    const emailOnly = inputValue.match(/\d+/g);

    let withoutSpecials = inputValue.replace(/[^\w\s]/g, "");

    switch (fieldName) {
      case "firstName":
        setFirstName(lettersOnly);
        break;
        case "personalEmail":
          setPersonalEmail(emailOnly);
          break;
      case "lastName":
        setLastName(lettersOnly);
        break;
      case "otherName":
        setOthername(lettersOnly);
        break;
      case "contactAddress":
        setContactAddress(withoutSpecials);
        break;
      case "addressKin":
        setNokA(withoutSpecials);
        break;

      default:
        break;
    }
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const handleEmployeeUpdate = (type, e) => {
    e.preventDefault();
    const workInfo = {
      pfaCode: pfa,
      pfaRsaPin: info.pfaRsaPin,
      tin: taxId,
      level,
      employeeType,
      level,
      taxState: taxState,
      position: position,
      department: department,
      employerId: info.employerId,
    };
    const personalInfo = {
      contactAddress: contactAddress,
      country: country,
      employerId: info.employerId,
      firstName: firstname,
      gender: gender,
      lastName: lastname,
      maritalStatus: maritalStatus,
      mobile: mobile,
      nextOfKin: nextOfKin,
      personalEmail,
      nextOfKinAddress: nextOfKinAddress,
      nextOfKinPhone: nextOfKinPhone,
      otherName: othername,
    };
    if (type === "Work") {
      setLoad2(true);
    } else {
      setLoad1(true);
    }
    axios
      .put(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,

        type === "Work" ? workInfo : personalInfo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad1(false);
        setLoad2(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            // window.location.reload(false)
          }, 2500);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad1(false);
        setLoad2(false);
        if (err) {
        }
      });
  };

  const processPersonal = (data) => {
    setFullData(data);
    var dataStringify = JSON?.stringify(data);
    sessionStorage?.setItem("personalInfo", dataStringify);
    setTabs("2");
  };

  useEffect(() => {
    setValue("address", "");
    setValue("addressKin", "");
  }, []);

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  console.log(errors);

  return (
    <>
      <div>
        <form>
          <div className="row">
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  First Name    <span className="text-danger">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={firstname}
                  maxLength={30}
                  {...register("firstName", {
                    required: "First Name is required!",
                    maxLength: {
                      value: 30,
                      message: `First name cannnot be more than 30 characters`,
                    },
                  })}
                  onChange={handleInputChange("firstName")}
                />
                {errors.firstName && (
                  <span className="validate-error">
                    {errors.firstName.message}
                  </span>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Last Name    <span className="text-danger">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={lastname}
                  maxLength={30}
                  {...register("lastName", {
                    required: "Last Name is required!",
                    maxLength: {
                      value: 30,
                      message: `Last name cannnot be more than 30 characters`,
                    },
                  })}
                  onChange={handleInputChange("lastName")}
                />
                {errors.lastName && (
                  <span className="validate-error">
                    {errors.lastName.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Other Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  dis
                  {...register("otherName", {
                    required: false,
                  })}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => setOthername(e.target.value)}
                  value={othername}
                  maxLength={30}
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Personal Email <span className="text-danger ">* </span>
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            name="workEmail"
                            value={personalEmail}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("personalEmail", {
                              required: "Personal Email is required!",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                                message: "Invalid Email Format",
                              },
                              onChange: (e) => setPersonalEmail(e.target.value),
                              value: personalEmail,
                            })}
                          />
                          {errors.personalEmail && (
                            <span className="validate-error">
                              {errors.personalEmail.message}
                            </span>
                          )}
                        </div>
                      </div>
            <div class="col-sm-12 col-md-6">
              <div className="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Date of Birth    <span className="text-danger">
                    *
                  </span>
                </label>

                <Controller
                  name="dob"
                  control={control}
                  {...register("dob", {
                    required: "Date of birth is required!",
                  })}
                  render={({ field }) => (
                    <DatePicker
                      onChange={onChange}
                      style={{ height: "3rem", width: "100%", borderRadius:'10px' }}
                      disabledDate={(current) => current.isAfter(moment())}
                      value={dob ? dayjs(dob, dateFormat) : ""}

                    />
                  )}
                />
                {errors.dob && (
                  <span className="validate-error">{errors.dob.message}</span>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Mobile    <span className="text-danger">
                    *
                  </span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={mobile}
                  {...register("mobile", {
                    required: "Mobile Number is required!",
                    onChange: handleChangeNumber.bind(this, "mobile", 11),
                  })}
                />
                {errors.mobile && (
                  <span className="validate-error">
                    {errors.mobile.message}
                  </span>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Marital Status    <span className="text-danger">
                    *
                  </span>
                </label>
                <select
                  class="form-select"
                  value={maritalStatus}
                  aria-label="Default select example"
                  {...register("maritalStatus", {
                    required: "Marital Status is required!",
                    onChange: (e) => setMaritalStatus(e.target.value),
                    value: maritalStatus,
                  })}
                >
                  <option selected>Select</option>
                  {maritalData.map((marital) => {
                    return <option>{marital.value}</option>;
                  })}
                </select>
                {errors.maritalStatus && (
                  <span className="validate-error">
                    {errors.maritalStatus.message}
                  </span>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Gender    <span className="text-danger">
                    *
                  </span>
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={gender}
                  {...register("gender", {
                    required: "Gender is required!",
                    onChange: (e) => setGender(e.target.value),
                    value: gender,
                  })}
                >
                  <option selected>Select</option>
                  {genderData.map((gender) => {
                    return <option>{gender.value}</option>;
                  })}
                </select>
                {errors.gender && (
                  <span className="validate-error">
                    {errors.gender.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
          
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label for="" class="">
                    Contact Address <span className="text-danger ">* </span>
                  </label>
                  <div
                    style={{ color: "#1fc157", cursor: "pointer" }}
                    onClick={() => {
                      setMnaualAddress(!mmanualAddress);
                      setContactAddress("");
                      setValue("addressContact", "");
                      setValue("address", "");
                    }}
                  >
                    {mmanualAddress ? (
                      <div
                        style={{
                          background: "#1FC157",
                          color: "white",
                          borderRadius: "7px",
                          padding: "2px 8px",
                          whiteSpace:'nowrap',
                          cursor: "pointer",
                        }}
                      >
                        Enter Address Automatically
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "#1FC157",
                          color: "white",
                          borderRadius: "7px",
                          padding: "2px 8px",
                          whiteSpace:'nowrap',
                          cursor: "pointer",
                        }}
                      >
                        Enter Address Manually
                      </div>
                    )}
                  </div>
                </div>
                {mmanualAddress ? (
                  <div>
                    <input
                      type="text"
                      class="form-control mt-2"
                      name="address mt-2"
                      id="exampleInputEmail1"
                      value={contactAddress}
                      aria-describedby="emailHelp"
                      {...register("address", {
                        required: "Address is required!",
                        onChange: (e) => setContactAddress(e?.target?.value),
                      })}
                      maxLength="200"
                    />
                    {errors.address && (
                      <span className="validate-error">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="pt-2">
                    <Location
                      setAddress={setContactAddress}
                      setCity={setCity}
                      setState={setState}
                      setLocationInfo={setLocationInfo}
                      register={register}
                      control={control}
                      errors={errors}
                      watch={watch}
                      registerVal={"address"}
                      setValue={setValue}
                      reset={reset}
                    />
                  </div>
                )}
                {info?.contactAddress && (
                  <div
                    className="font-weight-bold pt-1 b"
                    style={{ fontWeight: "600" }}
                  >
                    Current Address : {info?.contactAddress}
                  </div>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Country    <span className="text-danger">
                    *
                  </span>
                </label>

                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={country}
                  {...register("country", {
                    required: "Country is required!",
                    value: country,
                    onChange: countryChange,
                  })}
                >
                  <option selected>Select</option>
                  {countryData?.map((d) => {
                    return <option value={d?.code}>{d?.name}</option>;
                  })}
                </select>
                {errors.country && (
                  <span className="validate-error">
                    {errors.country.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
          
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Next of Kin Name    <span className="text-danger">
                    *
                  </span>
                </label>

                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={nextOfKin}
                  {...register("nok", {
                    required: "Next of Kin name is required!",
                    onChange: (e) => setNextOfkin(e.target?.value),
                  })}
                />
                {errors.nok && (
                  <span className="validate-error">{errors.nok.message}</span>
                )}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Next of Kin Phone Number    <span className="text-danger">
                    *
                  </span>
                </label>
                <input
                  type="number"
                  dis
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  {...register("nokPhone", {
                    required: "Next of Kin phone number is required!",
                    onChange: handleChangeNumber.bind(this, "nokPhone", 11),
                  })}
                  value={nextOfKinPhone}
                />
                {errors.nokPhone && (
                  <span className="validate-error">
                    {errors.nokPhone.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
           
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label for="" class="">
                    Next of Kin Address <span className="text-danger "> * </span>
                  </label>
                  <div
                    style={{ color: "#1fc157", cursor: "pointer" }}
                    onClick={() => {
                      setNokA("");
                      setMnaualAddressNok(!mmanualAddressNok);
                      setValue("addressKin", "");
                    }}
                  >
                    {mmanualAddressNok ? (
                      <div
                        className=""
                        style={{
                          background: "#1FC157",
                          color: "white",
                          borderRadius: "7px",
                          whiteSpace:'nowrap',
                          padding: "2px 8px",
                          cursor: "pointer",
                        }}
                      >
                        Enter Address Automatically
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "#1FC157",
                          color: "white",
                          borderRadius: "7px",
                          padding: "2px 8px",
                          whiteSpace:'nowrap',
                          cursor: "pointer",
                        }}
                      >
                        Enter Address Manually
                      </div>
                    )}
                  </div>
                </div>
                {mmanualAddressNok ? (
                  <div>
                    <input
                      type="text"
                      class="form-control mt-2"
                      name="nokA"
                      value={nokA}
                      aria-describedby="emailHelp"
                      {...register("nokA", {
                        required: "Next of Kin Address is required!",
                        onChange: (e) => setNokA(e?.target?.value),

                      })}
                      maxLength="200"
                    />
                    {errors.nokA && (
                      <span className="validate-error">
                        {errors.nokA.message}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="pt-2">
                    <Location2
                      setAddress={setNokA}
                      setCity={setCity}
                      setState={setState}
                      setLocationInfo={setLocationInfo}
                      register={register}
                      control={control}
                      errors={errors}
                      watch={watch}
                      reset={reset}
                      setValue={setValue}
                      registerVal={"addressKin"}
                      setAddressContact={setContactAddress}
                    />
                  </div>
                )}
                  {info?.nextOfKinAddress && (
                  <div
                    className="font-weight-bold pt-1 b"
                    style={{ fontWeight: "600" }}
                  >
                    Current Next of kin Address : {info?.nextOfKinAddress}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{
                background: "#1FC157",
                marginTop: "1rem",
                color: "white",
              }}
              onClick={handleSubmit(processPersonal)}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

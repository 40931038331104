import React, { useState, useEffect } from "react";
import Tabs from "./tab";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
import { notification, DatePicker, Spin } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { address } from "faker/lib/locales/az";
import { useForm, Controller } from "react-hook-form";
import Location from "../../../containers/Location/Location1";
import Location2 from "../../../containers/Location/Location2";
const dateFormat = "YYYY/MM/DD";

export default function EmployeeInfo(props) {
  const onboardingId = sessionStorage?.getItem("onboardID");
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [personalEmail, setPersonalEmail] = useState("")
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState("");
  const [locationInfo, setLocationInfo] = useState({});
  const [dob, setDob] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const [contactAddress, setAddress] = useState("");
  const [otherName, setOthername] = useState("");
  const [gender, setGender] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [nextOfKinAddress, setNokAddress] = useState("");
  const [nextOfKinPhone, setNokPhone] = useState("");
  const [nextOfKin, setNok] = useState("");
  let info;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const onChange = (date, dateString) => {
    setDob(date);
  };

  const getInfo = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/onboarding-link/invite/${onboardingId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          info = res.data.result;
          setAddress(info?.contactAddress);
          setStatusVal(info?.reviewStatus);
          setGender(info?.gender);
          setFname(info?.firstName);
          setLname(info?.lastName);
          setOthername(info?.otherName);
          setNokAddress(info?.nextOfKinAddress);
          setNok(info?.nextOfKin);
          setNokPhone(info?.nextOfKinPhone?.replace("+", ""));
          setMaritalStatus(info?.maritalStatus);
          setMobile(info?.mobile?.replace("+", ""));
          setCountry(info?.country);
          setPersonalEmail(info?.personalEmail)
          setDob(info?.dateOfBirth);
          setPersonalEmail(info?.personalEmail)
          setValue("firstName", info?.firstName);
          setValue("lastName", info?.lastName);
          setValue("gender", info?.gender);
          setValue("maritalStatus", info?.maritalStatus);
          setValue("address", info?.contactAddress);
          setValue("personalEmail", info?.personalEmail)
  
          setValue("addressKin", info?.contactAddress);
          setValue("nok", info?.nextOfKin);
          setValue("nokPhone", info?.nextOfKinPhone?.replace("+", ""));
          setValue("mobile", info?.mobile?.replace("+", ""));
          setValue("nokA", info?.nextOfKinAddress);
          setValue("country", info?.country);
          setValue("otherName", info?.otherName);
          setValue("dob", info?.dateOfBirth);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  console.log(genderData);
  console.log(maritalData);

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNokPhone(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const handleInputChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const lettersOnly = inputValue.replace(/[^A-Za-z]/g, "");
    const lettersOnlyandSpace = inputValue.replace(/^(?! )[A-Za-z\s]*$/g, "");

    switch (fieldName) {
      case "firstName":
        setFname(lettersOnly);
        break;
      case "lastName":
        setLname(lettersOnly);
        break;
      case "otherName":
        setOthername(lettersOnly);
        break;
      case "nok":
        setNok(inputValue);
        break;

      default:
        break;
    }
  };

  const goNext = (data) => {
    const datae = {
      fname: fname,
      lname: lname,
      otherName: otherName,
      gender: gender,
      personalEmail,
      maritalStatus: maritalStatus,
      nextOfKin: nextOfKin,
      nextOfKinAddress: nextOfKinAddress ? nextOfKinAddress : data?.addressKin,
      mobile: mobile,
      dob: dob,
      nokPhone: nextOfKinPhone,
      country: country,
      address: contactAddress ? contactAddress : data?.address,
    };
    var dataStringify = JSON?.stringify(datae);
    sessionStorage?.setItem("emp_personalInfo", dataStringify);
    history?.push("/employee-payment-info");
  };

  useEffect(() => {
    getInfo();
    getCounties();
  }, []);

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {load && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {!load && (
              <div>
                <div>
                  <div>
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-describedby="emailHelp"
                                maxLength="30"
                                {...register("firstName", {
                                  required: "First name is required",
                                  maxLength: {
                                    value: 30,
                                    message: `First name cannnot be more than 30 characters`,
                                  },
                                })}
                                onChange={handleInputChange("firstName")}
                                value={fname}
                              />
                              {errors.firstName && (
                                <span className="validate-error">
                                  {errors.firstName.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                maxLength="30"
                                {...register("lastName", {
                                  required: "Last name is required",
                                  maxLength: {
                                    value: 30,
                                    message: `Last name cannnot be more than 30 characters`,
                                  },
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-describedby="emailHelp"
                                onChange={handleInputChange("lastName")}
                                value={lname}
                              />
                              {errors.lastName && (
                                <span className="validate-error">
                                  {errors.lastName.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Other Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleInputChange("otherName")}
                                value={otherName}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label">
                                Personal Email <span className="text-danger ">* </span>
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                name="workEmail"
                                value={personalEmail}
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                {...register("personalEmail", {
                                  required: "Personal Email is required!",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                                    message: "Invalid Email Format",
                                  },
                                  onChange: (e) => setPersonalEmail(e.target.value),
                                  value: personalEmail,
                                })}
                              />
                              {errors.personalEmail && (
                                <span className="validate-error">
                                  {errors.personalEmail.message}
                                </span>
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Marital Status
                              </label>
                              <select
                                class="form-select"
                                name="paymentFunc"
                                {...register("maritalStatis", {
                                  required: "Marital Status is required",
                                })}
                                value={maritalStatus}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-label="Default select example"
                                onChange={(e) =>
                                  setMaritalStatus(e.target.value)
                                }
                              >
                                <option selected>Select </option>
                                {maritalData?.map((d) => {
                                  return (
                                    <option value={d?.value}>{d.value}</option>
                                  );
                                })}
                              </select>
                              {errors.maritalStatis && (
                                <span className="validate-error">
                                  {errors.maritalStatis.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Gender
                              </label>

                              <select
                                class="form-select"
                                name="paymentFunc"
                                value={gender}
                                {...register("gender", {
                                  required: "Gender is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-label="Default select example"
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option selected>Select </option>
                                {genderData?.map((d) => {
                                  return (
                                    <option value={d?.code}>{d.value}</option>
                                  );
                                })}
                              </select>
                              {errors.gender && (
                                <span className="validate-error">
                                  {errors.gender.message}
                                </span>
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label for="" class="">
                                  Contact Address{" "}
                                  <span className="text-danger "></span>
                                </label>
                                {statusVal !== "APPROVED" && (
                                  <div
                                    style={{
                                      color: "#1fc157",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setMnaualAddress(!mmanualAddress);
                                      setAddress("");
                                      setValue("address", "");
                                    }}
                                  >
                                    {mmanualAddress ? (
                                      <div
                                        style={{
                                          background: "#1FC157",
                                          color: "white",
                                          borderRadius: "7px",
                                          padding: "2px 8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Enter Address Automatically
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          background: "#1FC157",
                                          color: "white",
                                          borderRadius: "7px",
                                          padding: "2px 8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Enter Address Manually
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              {mmanualAddress ? (
                                <div>
                                  <input
                                    type="text"
                                    class="form-control mt-2"
                                    name="address mt-2"
                                    id="exampleInputEmail1"
                                    value={contactAddress}
                                    onChange={(e) => setAddress(e.target.value)}
                                    disabled={
                                      statusVal === "APPROVED" ? true : false
                                    }
                                    aria-describedby="emailHelp"
                                    {...register("address", {
                                      required: "Address is required!",
                                      onChange: (e) =>
                                        setAddress(e?.target?.value),
                                    })}
                                    maxLength="200"
                                  />
                                  {errors.address && (
                                    <span className="validate-error">
                                      {errors.address.message}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div className="pt-2">
                                  {statusVal === "APPROVED" ? (
                                    <div>
                                      <input
                                        type="text"
                                        class="form-control mt-2"
                                        name="address mt-2"
                                        id="exampleInputEmail1"
                                        value={contactAddress}
                                        disabled={
                                          statusVal === "APPROVED"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <Location
                                        setAddress={setAddress}
                                        setLocationInfo={setLocationInfo}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        watch={watch}
                                        registerVal={"contactAddress"}
                                        setValue={setValue}
                                        reset={reset}
                                      />
                                      {contactAddress && (
                                        <div
                                          className="font-weight-bold pt-1 b"
                                          style={{ fontWeight: "600" }}
                                        >
                                          Current Next of Kin Address :{" "}
                                          {contactAddress}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Date of Birth
                              </label>
                              <Controller
                                name="dob"
                                control={control}
                                {...register("dob", {
                                  required: "Date of birth is required!",
                                })}
                                render={({ field }) => (
                                  <DatePicker
                                    onChange={onChange}
                                    disabled={
                                      statusVal === "APPROVED" ? true : false
                                    }
                                    style={{
                                      height: "3rem",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                    disabledDate={(current) =>
                                      current.isAfter(moment())
                                    }
                                    value={dob ? dayjs(dob, dateFormat) : ""}
                                  />
                                )}
                              />
                              {errors.dob && (
                                <span className="validate-error">
                                  {errors.dob.message}
                                </span>
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Mobile
                              </label>
                              <input
                                type="number"
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                {...register("mobile", {
                                  required: "Mobile Number is required!",
                                  onChange: handleChangeNumber.bind(
                                    this,
                                    "mobile",
                                    15
                                  ),
                                  value: mobile,
                                })}
                                value={mobile}
                              />
                              {errors.mobile && (
                                <span className="validate-error">
                                  {errors.mobile.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Country
                              </label>
                              <select
                                class="form-select"
                                name="paymentFunc"
                                value={country}
                                {...register("country", {
                                  required: "Country is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-label="Default select example"
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option selected>Select </option>
                                {countryData?.map((d) => {
                                  return (
                                    <option value={d?.code}>{d.name}</option>
                                  );
                                })}
                              </select>
                              {errors.country && (
                                <span className="validate-error">
                                  {errors.country.message}
                                </span>
                              )}
                            </div>
                          </div>

                        </div>

                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Next of Kin Name
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                {...register("nok", {
                                  required: "Next of kin name is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                value={nextOfKin}
                                onChange={(e) => setNok(e.target.value)}
                              />
                              {errors.nok && (
                                <span className="validate-error">
                                  {errors.nok.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Next of Kin Phone Number
                              </label>
                              <input
                                type="number"
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                class="form-control"
                                id="exampleInputEmail1"
                                {...register("nokPhone", {
                                  required:
                                    "Next of Kin phone number is required!",
                                  onChange: handleChangeNumber.bind(
                                    this,
                                    "nokPhone",
                                    15
                                  ),
                                  value: nextOfKinPhone,
                                })}
                                aria-describedby="emailHelp"
                                value={nextOfKinPhone}
                                onChange={(e) => setNokPhone(e.target.value)}
                              />
                              {errors.nokPhone && (
                                <span className="validate-error">
                                  {errors.nokPhone.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label for="" class="">
                                  Next of Kin Address{" "}
                                  <span className="text-danger ">* </span>
                                </label>
                                {statusVal !== "APPROVED" && (
                                  <div
                                    style={{
                                      color: "#1fc157",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setNokAddress("");
                                      setMnaualAddressNok(!mmanualAddressNok);
                                      setValue("addressKin", "");
                                    }}
                                  >
                                    {mmanualAddressNok ? (
                                      <div
                                        className=""
                                        style={{
                                          background: "#1FC157",
                                          color: "white",
                                          borderRadius: "7px",
                                          padding: "2px 8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Enter Address Automatically
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          background: "#1FC157",
                                          color: "white",
                                          borderRadius: "7px",
                                          padding: "2px 8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Enter Address Manually
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              {mmanualAddressNok ? (
                                <div>
                                  <input
                                    type="text"
                                    class="form-control mt-2"
                                    name="nokA"
                                    value={nextOfKinAddress}
                                    aria-describedby="emailHelp"
                                    {...register("nokA", {
                                      required:
                                        "Next of Kin Address is required!",
                                      onChange: (e) =>
                                        setNokAddress(e?.target?.value),
                                    })}
                                    maxLength="200"
                                  />
                                  {errors.nokA && (
                                    <span className="validate-error">
                                      {errors.nokA.message}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div className="pt-2">
                                  {statusVal === "APPROVED" ? (
                                    <div>
                                      <input
                                        type="text"
                                        class="form-control mt-2"
                                        name="address mt-2"
                                        id="exampleInputEmail1"
                                        value={nextOfKinAddress}
                                        disabled={
                                          statusVal === "APPROVED"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <Location2
                                        setAddress={setNokAddress}
                                        setLocationInfo={setLocationInfo}
                                        register={register}
                                        control={control}
                                        errors={errors}
                                        watch={watch}
                                        reset={reset}
                                        setValue={setValue}
                                        registerVal={"nokA"}
                                        setAddressContact={setNokAddress}
                                      />
                                      {nextOfKinAddress && (
                                        <div
                                          className="font-weight-bold pt-1 b"
                                          style={{ fontWeight: "600" }}
                                        >
                                          Current Next of Kin Address :{" "}
                                          {nextOfKinAddress}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                            onClick={handleSubmit(goNext)}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

import React, { useState, useEffect } from "react";
import Tabs from "./tab";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import CrudOptimizer from "../../../containers/UtilityCrudEmployer/Index";
import { NumberFormatter } from "../../../formatter/index";
import { useHistory } from "react-router-dom";
import { notification, DatePicker, Modal, Spin, Checkbox } from "antd";
import dayjs from "dayjs";
const dateFormat = "YYYY/MM/DD";
export default function EmployeeInfo(props) {
  const [loading, currencyDatas] = useHttp(
    `${config.baseUrl}/v1/wallets/employer/currencies/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const [checkWorkOptions, setCheckWorkOptions] = useState([])
  const [checkWorkOptionsVal, setCheckWorkOptionsVal] = useState([])
  const [monthlyNetSalaryVal, setMonthltyNetSalaryValue] = useState("")
  const [countryUser, setCountryUser] = useState("")

  const onChangeCheckbox = (checkedValues) => {
    setCheckWorkOptions(checkedValues)
  };

  const checkOptions = [
    {
      label: 'Pension',
      value: 'Pension',
    },

    {
      label: 'Tax',
      value: 'Tax',
    },
  ];

  const history = useHistory();
  const onboardingId = sessionStorage?.getItem("onboardID");
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [annualGrossery, setAnnualGrossery] = useState("");
  const [load1, setLoad1] = useState(false);
  const [paygroupData, setPaygroupData] = useState([]);
  const [loadCrudDatas, setCrudDatas] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [currency, setCurrency] = useState("");
  const [loadAcc, setLoadAcc] = useState(false);
  const [loadDec, setLoadDec] = useState(false);
  const [visible, setVisible] = useState(false);
  const [addTypeName, setAddTypeName] = useState("");
  const [lastname, setLastName] = useState("");
  const [employerInfo, setEmployerInfo] = useState({});
  const [reason, setReason] = useState("");
  const [positionDatas, setPositionData] = useState([]);
  const [departmentDatas, setDepartmentData] = useState([]);
  const [levelDatas, setEmployeeLevelData] = useState([]);
  const [bankLists, setBankLists] = useState([]);
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [paygroup, setPayGroup] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [departmentCreate, setDepartmentCreate] = useState(false);
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [pfa, setPfa] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");
  const [pfaData, setPfaData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [decModal, setOpenDecModal] = useState(false);
  const [employeeType, setEmployeeType] = useState("");
  const [position, setPosition] = useState("");
  const [pfaRsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [nextOfKin, setNextOfkin] = useState("");
  const [nextOfKinPhone, setNextOfkinPhone] = useState("");
  const [level, setLevel] = useState("");
  const [nextOfKinAddress, setNextOfkinAddress] = useState("");
  const query = new URLSearchParams(props.location.search);
  let inviteRef;

  const onChangeHireDate = (date, dateString) => {
    setHireDate(date);
    setValue("hireDate", date);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    inviteRef = query.get("ref");
    if (inviteRef) {
      sessionStorage?.setItem("onboardID", inviteRef);
    }
  }, []);

  useEffect(() => {
    if (loadCrudDatas) {
    }
  }, [loadCrudDatas]);

  const getPosition = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );

  const getLevelsData = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setEmployeeLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getDepartment = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getDepartment();
    getPosition();
    getLevelsData();
  }, []);

  const loadAllCrus = () => {
    getDepartment();
    getPosition();
    getLevelsData();
  };

  useEffect(() => {
    getContactDetails();
    getContactDetailsEmployer();
  }, []);

  const getContactDetailsEmployer = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          getPaygroup(contactDetails?.countries[0]?.code);
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/onboarding-link/invite/${onboardingId ? onboardingId : inviteRef
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setInfo(contactDetails);
          getBanksByCountry(contactDetails?.country);
          getPfa(contactDetails?.country);
          setEmployeeCode(contactDetails?.employeeCode);
          setPosition(contactDetails?.position);
          setLevel(contactDetails?.level);
          setDepartment(contactDetails?.department);
          setCountryUser(contactDetails?.country)
          setCurrency(contactDetails?.salaryCurrency);
          setEmployeeType(contactDetails?.employeeType);
          setPayGroup(contactDetails?.payGroup);
          setHireDate(contactDetails?.hireDate);
          setValue("annualGrossery", contactDetails?.annualGrossSalary);
          if (contactDetails?.computePension) {
            checkWorkOptionsVal.push('Pension')
          }
          if (contactDetails?.computeTax) {
            checkWorkOptionsVal.push('Tax')
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNextOfkinPhone(truncatedValue);
          break;
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;

        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`)
      .then((res) => {
        if (res.data.status) {
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/PFA/${country}`)
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;

          setPfaData(
            arr?.map((d) => ({
              value: d?.code,
              label: d?.value,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getBankName = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.label) {
      return bank?.label;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.label;
    }
  };

  const getBankCode = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.value) {
      return bank?.value;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.value;
    }
  };

  const getpfaName = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.label) {
      return pfa?.label;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.label;
    }
  };

  const getPfaCode = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.value) {
      return pfa?.value;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.value;
    }
  };

  const handleTypeCase = (data) => {
    setLoadAcc(true);
    var paymentinfodata = sessionStorage.getItem("emp_paymentlInfo");
    var personalinfodata = sessionStorage.getItem("emp_personalInfo");
    var personalInfo = JSON?.parse(personalinfodata);
    var paymentInfo = JSON?.parse(paymentinfodata);

    axios
      .post(
        `${config.baseUrl
        }/v1/onboarding-link/${onboardingId}/approve-invite?employerId=${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          annualGrossSalary: data?.annualGrossery,
          department: data?.department,
          employeeCode: data?.employeeCode,
          employeeType: data?.employeeType,
          hireDate: data?.hireDate,
          level: data?.level,
          payGroup: paygroup,
          position: data?.position,
          salaryCurrency: data?.currency,
          workEmail: info?.workEmail,
          mobile: personalInfo?.mobile,
          firstName: personalInfo?.fname,
          otherName: personalInfo?.otherName,
          lastName: personalInfo?.lname,
          gender: personalInfo?.gender,
          maritalStatus: personalInfo?.maritalStatus,
          computePension: checkWorkOptions?.includes('Pension'),
          computeTax: checkWorkOptions?.includes('Tax'),
          dateOfBirth: personalInfo?.dob,
          contactAddress: personalInfo?.address,
          country: personalInfo?.country,
          personalEmail: personalInfo?.personalEmail,

          nextOfKin: personalInfo?.nextOfKin,
          nextOfKinPhone: personalInfo?.nokPhone,
          nextOfKinAddress: personalInfo?.nextOfKinAddress,
          pfaCode:
            paymentInfo?.pfaName === "Select"
              ? ""
              : getPfaCode(paymentInfo?.pfaName),
          pfaName:
            paymentInfo?.pfaName === "Select"
              ? ""
              : getpfaName(paymentInfo?.pfaName),
          pfaRsaPin: paymentInfo?.pfaRsaPin,
          tin: paymentInfo?.tin,
          tinState: paymentInfo?.taxState,
          paymentOption: paymentInfo?.paymentOption,
          paymentCountry: paymentInfo?.paymentCountry,
          paymentCurrency: paymentInfo?.currency,
          institutionCode: getBankCode(paymentInfo?.bankName),
          institutionName: getBankName(paymentInfo?.bankName),
          institutionBranch: "",
          accountIdentifier: paymentInfo?.accountNo,
          accountName: paymentInfo?.accountName,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadAcc(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            history.push("/employee_onboarding");
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadAcc(false);
        if (err) {
        }
      });
  };

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.currency} key={currency.code}>
        {currency.currency}
      </option>
    );
  });

  const onDecline = () => {
    if (!reason) {
      Notification("error", "Error", "reason to decline is required");
      return;
    }
    setLoadDec(true);
    axios
      .post(
        `${config.baseUrl
        }/v1/onboarding-link/decline-invite?employerId=${sessionStorage?.getItem(
          "employer_id"
        )}&onboardingLinkId=${onboardingId}&reviewComment=${reason}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadDec(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            history.push("/self-onboarding");
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadDec(false);
        if (err) {
        }
      });
  };

  const getPaygroup = (country) => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const paygroupOptions = paygroupData.map((paygroup) => {
    return (
      <option value={paygroup.code} key={paygroup.id}>
        {paygroup.groupName}
      </option>
    );
  });

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const handleEmployeeUpdate = (type, e) => {
    e.preventDefault();
    const workInfo = {
      pfaCode: pfa,
      pfaRsaPin: info.pfaRsaPin,
      tin: taxId,
      level,
      employeeType,
      level,
      taxState: taxState,
      position: position,
      department: department,
      employerId: info.employerId,
    };
    const personalInfo = {
      contactAddress: contactAddress,
      country: country,
      employerId: info.employerId,
      firstName: firstname,
      gender: gender,
      lastName: lastname,
      maritalStatus: maritalStatus,
      mobile: mobile,
      nextOfKin: nextOfKin,
      nextOfKinAddress: nextOfKinAddress,
      nextOfKinPhone: nextOfKinPhone,
      otherName: othername,
    };
    if (type === "Work") {
      setLoad2(true);
    } else {
      setLoad1(true);
    }
    axios
      .put(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,

        type === "Work" ? workInfo : personalInfo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad1(false);
        setLoad2(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          setTimeout(() => {
            // window.location.reload(false)
          }, 2500);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad1(false);
        setLoad2(false);
        if (err) {
        }
      });
  };

  const closeModal = () => {
    setVisible(false);
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };


  const getMonthlyNetSalary = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employees/pay-structure?annualGrossSalary=${annualGrossery}&computeNHF=false&computePension=${checkWorkOptions?.includes('Pension')}&computeTax=${checkWorkOptions?.includes('Tax')}&country=${countryUser}&currency=${currency}&employeeType=${employeeType}&employerId=${sessionStorage?.getItem('employer_id')}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMonthltyNetSalaryValue(res.data.result.monthlyNetSalary)
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (annualGrossery) {
      getMonthlyNetSalary()
    }
  }, [annualGrossery, checkWorkOptions, currency, employeeType])

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {loadEmployeeInfo && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {!loadEmployeeInfo && (
              <div>
                <div className="col-sm-12 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Code
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                {...register("employeeCode", {
                                  required: "Employee Code is required!",
                                  onChange: (e) =>
                                    setEmployeeCode(e.target?.value),
                                })}
                                aria-describedby="emailHelp"
                                maxLength={12}
                                value={employeeCode}
                              />
                              {errors.employeeCode && (
                                <span className="validate-error">
                                  {errors.employeeCode.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Work Email
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                disabled
                                value={info?.workEmail}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Department
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Department");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Department</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("department", {
                                  required: "Department is required!",
                                  onChange: (e) =>
                                    setDepartment(e.target?.value),
                                  value: department,
                                })}
                                value={department}
                              >
                                <option selected>Select</option>
                                {departmentDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.department && (
                                <span className="validate-error">
                                  {errors.department.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Position
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Position");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Position</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("position", {
                                  required: "Position is required!",
                                  onChange: (e) => setPosition(e.target?.value),
                                  value: position,
                                })}
                                value={position}
                              >
                                <option selected>Select</option>
                                {positionDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {" "}
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.position && (
                                <span className="validate-error">
                                  {errors.position.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Employee Level
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Employee Level");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Employee Level</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={level}
                                {...register("level", {
                                  required: "Employee level is required!",
                                  onChange: (e) => setLevel(e.target?.value),
                                  value: level,
                                })}
                              >
                                <option selected>Select</option>
                                {levelDatas.map((level) => {
                                  return (
                                    <option value={level.level}>
                                      {level.level}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.level && (
                                <span className="validate-error">
                                  {errors.level.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Type
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={employeeType}
                                {...register("employeeType", {
                                  required: "Employee Type is required!",
                                  onChange: (e) => {
                                    setEmployeeType(e.target?.value);
                                    if (e.target?.value !== "FULL-TIME") {
                                      setPayGroup("");
                                    }
                                  },
                                  value: employeeType,
                                })}
                              >
                                <option selected>Select</option>
                                {employeeTypeData.map((type) => {
                                  return (
                                    <option value={type.value}>
                                      {type.value}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.employeeType && (
                                <span className="validate-error">
                                  {errors.employeeType.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">

                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Hire Date{" "}
                                <span className="text-danger ">* </span>
                              </label>{" "}
                              <Controller
                                name="hireDate"
                                control={control}
                                {...register("hireDate", {
                                  required: "Hire Date is required!",
                                })}
                                render={({ field }) => (
                                  <DatePicker
                                    onChange={onChangeHireDate}
                                    style={{ height: "3rem", width: "100%" }}
                                    value={
                                      hireDate
                                        ? dayjs(hireDate, dateFormat)
                                        : ""
                                    }
                                    disabledDate={(current) =>
                                      current.isAfter(moment())
                                    }
                                  />
                                )}
                              />
                              {errors.hireDate && (
                                <span className="validate-error">
                                  {errors.hireDate.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="pt-2 mb-3">
                              <div>
                                <label for="exampleInputEmail1" class="form-label">
                                  Statutory Deductions
                                </label>
                              </div>
                              <div>
                                <Checkbox.Group options={checkOptions} defaultValue={checkWorkOptionsVal} onChange={onChangeCheckbox} />
                              </div>

                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Salary Currency
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("currency", {
                                  required: "Salary Currency is required!",
                                  onChange: (e) => setCurrency(e.target?.value),
                                  value: currency,
                                })}
                                value={currency}
                              >
                                <option selected>Select</option>
                                {currencyOptions}
                              </select>
                              {errors.currency && (
                                <span className="validate-error">
                                  {errors.currency.message}
                                </span>
                              )}
                            </div>
                          </div>
                          {currency && hireDate && employeeType &&
                            <div class="col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Annual Gross Salary{" "}
                                  <span className="text-danger ">* </span>
                                </label>
                                <Controller
                                  name="annualGrossery"
                                  control={control}
                                  {...register("annualGrossery", {
                                    required: "Annual Gross Salary is required!",
                                    onChange: (e) =>
                                      setAnnualGrossery(e.target.value),
                                    value: annualGrossery,
                                  })}
                                  render={({ field }) => (
                                    <NumberFormatter
                                      {...field}
                                      label="Annual Gross Salary"
                                      name="amount"
                                      error={errors.annualGrossery?.message}
                                      prefix={"₦"}
                                    />
                                  )}
                                />
                                {monthlyNetSalaryVal &&
                                  <div className="text" style={{ color: '#1fc157', textAlign: 'right' }}>
                                    Monthly Net Salary- {currency} {monthlyNetSalaryVal ? Intl.NumberFormat("en-US").format(monthlyNetSalaryVal) : 0}
                                  </div>}

                                {errors.annualGrossery && (
                                  <span className="validate-error">
                                    {errors.annualGrossery.message}
                                  </span>
                                )}
                              </div>
                            </div>}

                        </div>

                        <br />
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              style={{
                                background: "#1FC157",
                                marginTop: "1rem",
                                color: "white",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push("/employee-payment-info");
                              }}
                            >
                              Back
                            </button>
                          </div>
                          {info?.reviewStatus !== "APPROVED" && (
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style={{
                                  background: "#1FC157",
                                  marginTop: "1rem",
                                  color: "white",
                                }}
                                onClick={handleSubmit(handleTypeCase)}
                              >
                                {loadAcc ? (
                                  <ThreeDots
                                    visible={loadAcc}
                                    height="20"
                                    width="50"
                                    color="#ffffff"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                ) : (
                                  "Approve"
                                )}
                              </button>
                              <button
                                type="submit"
                                class="btn btn-primary mr-2"
                                style={{
                                  background: "red",
                                  marginTop: "1rem",
                                  color: "white",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenDecModal(true);
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CrudOptimizer
        name={addTypeName}
        visible={visible}
        setVisible={setVisible}
        closeModal={closeModal}
        setCrudDatas={setCrudDatas}
        loadAllCrus={loadAllCrus}
      />
      <Modal
        title={"Decline Invite"}
        visible={decModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setOpenDecModal(false);
          setReason("");
        }}
      >
        <div class="">
          <div className="text-center">{loadDec && <Spin />}</div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Decline Reason
            </label>
            <input
              type="text"
              class="form-control"
              rows="20"
              id="exampleInputEmail1"
              style={{ height: "100px" }}
              aria-describedby="emailHelp"
              onChange={(e) => {
                setReason(e.target?.value);
              }}
              value={reason}
            ></input>
            <br />
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  marginTop: "1rem",
                  color: "white",
                }}
                onClick={onDecline}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
